<template>
  <v-row justify="center">
    <v-dialog v-model="isGenerateInboundModalOpen" persistent max-width="360">
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>{{ $t("generate_inbound") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="toggleGenerateInbound" class="ma-0">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <p class="text-center">
            Are you sure you want to validate the inbound of the
            {{ currentInbound.supplyOrder?.tracking_number }} order
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            text
            @click="toggleGenerateInbound"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            class="primary"
            text
            @click="confirmInbound()"
            :loading="isLoading"
          >
            {{ $t("confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  props: {
    isGenerateInboundModalOpen: Boolean,
    toggleGenerateInbound: Function,
    generateInbound: Function,
    currentInbound: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async confirmInbound() {
      try {
        this.isLoading = true;
        await this.generateInbound(this.currentInbound);
        this.$store.dispatch("alerts/success", this.$t("created_successfully"));
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    validate() {
      this.$refs.form.validate();
    },
  },

  mounted() {
    if (this.currentInbound.bill_number) {
      this.bill_number = this.currentInbound.bill_number;
    }
  },
};
</script>